$ = require "jquery"
classNames = require "classnames"
{capitalize, has, isFunction} = require "lodash"

{ajax} = require "core/ajax.coffee"
{__} = require "core/lang"
io = require("lib/io").default
{error, notice} = require "core/messages.coffee"
routes = require("lib/routes").default

prefix =
  area: 'vote_area_'
  total: 'vote_total_'
  count: 'vote_count_'

voteTargets =
  comment: 'idComment'
  topic: 'idTopic'
  blog: 'idBlog'
  user: 'idUser'

revoteTimeLimits = {}
revoteWatchdog = () ->
  now = Date.now()
  for tl of revoteTimeLimits
    if !revoteTimeLimits.hasOwnProperty(tl) then continue
    if revoteTimeLimits[tl]['time_spamunlock'] < now # разблокируем возможность переголосовать по прошествии acl.vote.antispam_delay секунд после голосования (механизм антиспама)
      document.getElementById("#{prefix.area}#{tl}").classList.add 'vote-enabled'
      if !revoteTimeLimits[tl].hasOwnProperty('time_lock')
        delete revoteTimeLimits[tl] # нам не нужно далее следить за объектами, переголосование по которым не ограничено
        continue
    if revoteTimeLimits[tl]['time_lock'] <= now
      document.getElementById("#{prefix.area}#{tl}").classList.remove 'vote-enabled'
      delete revoteTimeLimits[tl]

initial_revotes = document.querySelectorAll('.vote-enabled[data-last-vote-time]')
for tl of initial_revotes
  if !initial_revotes.hasOwnProperty(tl) then continue
  configureRevoteLimits(initial_revotes[tl].dataset.target_type, initial_revotes[tl].dataset.target_id, parseInt(initial_revotes[tl].dataset.lastVoteTime)*1000)

setInterval(revoteWatchdog, 1000)

`function configureRevoteLimits(type, idTarget, voting_date) {
    let revote_time_limit = {}; let lock_vote = true;
    voting_date = voting_date || Date.now();
    // блокируем возможность переголосовать на acl.vote.antispam_delay секунды после голосования (механизм антиспама)
    // Capabilities см. в footer.tpl
    if(Capabilities['revotingAntispamLimit'])
        revote_time_limit['time_spamunlock'] = voting_date + Capabilities['revotingAntispamLimit']*1000;
    else
        lock_vote = false; // если антиспам в 0 секунд, не блокируем голосовалку
    // лимит на переголосование задан
    if(Capabilities.hasOwnProperty('revotingTimeLimit_'+type)) {
        if(Capabilities['revotingTimeLimit_'+type])
            revote_time_limit['time_lock'] = voting_date + Capabilities['revotingTimeLimit_'+type]*1000;
        // лимит на переголосование может быть установлен в 0
        else {
            lock_vote = true; // блокируем голосовалку независимо от антиспама
            if(revote_time_limit['time_spamunlock'])
                delete revote_time_limit['time_spamunlock'];
        }
    }
    // если антиспам отключён (0 секунд) и переголосование неограничено или же переголосование 0 секунд, то не добавляем голосовалку в таймер для отслеживания
    if(Object.keys(revote_time_limit).length > 0)
        revoteTimeLimits[type+'_'+idTarget] = revote_time_limit;

    return lock_vote;
}`

watchForRevoting = (object) ->
  if(object instanceof HTMLElement && object = object.querySelector(':scope .vote-enabled[data-last-vote-time]'))
    configureRevoteLimits(object.dataset.target_type, object.dataset.target_id, parseInt(object.dataset.lastVoteTime)*1000);

vote = (idTarget, objVote, value, type) ->
  unless voteTargets[type]
    return false
  unless objVote.parentNode.classList.contains("vote-enabled")
    return false

  `if(objVote.parentNode.classList.contains('voted')
    && ((objVote.parentNode.classList.contains('voted-up') && value > 0) || (objVote.parentNode.classList.contains('voted-down') && value < 0) || value == 0))
    return false;`

  objVote = $(objVote)
  params = {}
  params['value'] = value
  params[voteTargets[type]] = idTarget

  ajax routes.vote[type], params, (result) ->
    onVote idTarget, objVote, value, type, result


onVote = (idTarget, objVote, value, type, result) ->
  if result.bStateError
    error null, result.sMsg
    return
  notice null, result.sMsg

  iRating = parseFloat result.iRating
  iCountVote = parseInt result.iCountVote

  lock_vote = configureRevoteLimits(type,idTarget)

  divVoting = $ "##{prefix.area}#{type}_#{idTarget}"
  divTotal = $ "##{prefix.total}#{type}_#{idTarget}"
  divCount = $ "##{prefix.count}#{type}_#{idTarget}"

  divVoting.removeClass classNames "vote-count-positive", "vote-count-negative", "vote-count-zero", "vote-count-mixed", "not-voted", 'voted-up','voted-down','voted-zero',
    'vote-enabled': lock_vote == true
  divVoting.addClass classNames "voted",
    "voted-up": value > 0
    "voted-down": value < 0
    "voted-zero": value == 0
    "vote-count-positive": iRating > 0
    "vote-count-negative": iRating < 0
    "vote-count-zero": iRating == 0 and (iCountVote == 0 or type == "topic")
    "vote-count-mixed": iRating == 0 and (iCountVote > 0 and type != "topic")

  if divCount.length > 0 then divCount.text(iCountVote)
  divTotal.text if iRating > 0 then "+#{iRating}" else if iRating < 0 then result.iRating else 0
  divTotal[0].dataset.count = iCountVote

  #if(type == 'user')
  #  $("#user_skill_#{idTarget}").text result.iSkill


`const ajaxLoader = new io.AjaxLoader();
const getActiveVotelistBox = function() { return document.getElementById('vote-list-box'); };
const leaveVoteList = function(eventListenerType) {
    const vl_box = getActiveVotelistBox();
    if(!vl_box)
        return;
    vl_box.classList.add('hidden'); vl_box.id = ''; // чтобы getActiveVotelistBox() возвращал null сразу, а не в конце анимации
    setTimeout(Node.prototype.removeChild.bind(vl_box.parentNode), 500, vl_box);
    if(eventListenerType)
        window.removeEventListener(eventListenerType, onVoteListLeave);
};
const onVoteListLeave = function(e) {
    const vl_box = getActiveVotelistBox();
    if(!vl_box || e.target == vl_box || e.target.tagName == 'A' || vl_box.contains(e.target))
        return;
    leaveVoteList(e.type);
};
function getVotes(targetId, targetType, el) {
    if(el.dataset.target_type == "topic" && el.parentNode.classList.contains("not-voted") && el.parentNode.classList.contains("vote-enabled") /*&& UI.voteNeutral*/)
        return false;

    // если есть открытый список оценок, "закроем" его без удаления обработчика клика для закрытия, иначе поставим этот обработчик
    if(getActiveVotelistBox()) {
        ajaxLoader.abort();
        leaveVoteList();
    }
    else
        window.addEventListener('click',onVoteListLeave);

    const vl_box = document.createElement('div');
    vl_box.id = 'vote-list-box';
    vl_box.className = "vote-list-box for-"+targetType+" hidden loading";
    vl_box.setAttribute('data-first-implemented-by', 'Morano');
    el.parentNode.parentNode.append(vl_box);
    setTimeout(DOMTokenList.prototype.remove.bind(vl_box.classList), 10, 'hidden');

    ajaxLoader.setUrl(routes.vote.getVotes).setElementWithLoading('#vote-list-box').setData({'targetId':targetId,'targetType':targetType})
        .post()
        .then((result) => {
            // блок закрыли ещё до окончания загрузки списка
            if(!getActiveVotelistBox())
                return;

            if(result.aVotes.length == 0)
                vl_box.insertAdjacentHTML('beforeend','<div class="vote-list-separator">'+__('common.rating.no_rates',{'type':targetType})+'</div>');
            else {
                for(let i = 0; i < result.aVotes.length; i++) {
                    if(result.aVotes[i].type == 'separator') {
                        vl_box.insertAdjacentHTML('beforeend','<div class="vote-list-separator">'+result.aVotes[i].params.title+'</div>');
                        continue;
                    }
                    vote = result.aVotes[i].params;
                    vote.timestamp = result.aVotes[i].timestamp;
                    const line = document.createElement('div');
                    line.className = 'vote-list-item';
                    const profileLink = __makeProfileLink(vote.voterName, {
                        name: vote.voterName,
                        avatar: vote.voterAvatar
                    });
                    profileLink.classList.add('vli-profile');
                    line.appendChild(profileLink);

                    const time = document.createElement('time');
                    time.dateTime = (new Date(parseInt(vote.timestamp)*1000)).toISOString();
                    time.className = 'vli-time';
                    time.appendChild(document.createTextNode(vote.date));
                    line.appendChild(time);

                    const voteValue = document.createElement('span');
                    voteValue.dataset.value = vote.value == 0 ? '0' : (vote.value > 0 ? '+' : '−') + Math.abs(vote.value).toString();
                    voteValue.className = 'vli-vote';
                    line.appendChild(voteValue);

                    vl_box.appendChild(line);
                }
                if(vl_box.scrollHeight > vl_box.clientHeight) {
                    vl_box.style.overflowY = 'scroll';
                }
            }
        })
        .catch((result) => { vl_box.remove(); });
    return false;
}`

__makeProfileLink = (path, data) ->
  el = document.createElement "a"
  if path != null and data.name != null
    el.href = "/profile/"+path
    el.target = "_blank"
    el.className = "ls-user has-avatar"
    avatar = document.createElement "img"
    avatar.src = data.avatar
    el.appendChild avatar
    el.appendChild document.createTextNode(data.name)
  else
    el.href = "javascript://"
    el.className = "ls-user undefined"
    el.appendChild document.createTextNode("—")
  return el

module.exports = {vote, getVotes, watchForRevoting}
