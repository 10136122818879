// legacy LiveStreet i18n system
let messages = {};

String.prototype.tr = function(a, p) {
    var s;
    p = typeof p === 'string' ? p : '';
    s = this;
    for(let k in a) {
        if(!a.hasOwnProperty(k)) continue;
        let tk, tp;
        tk = p ? p.split('/') : [];
        tk[tk.length] = k;
        tp = tk.join('/');
        if(typeof a[k] === 'object')
            s = s.tr(a[k], tp);
        else
            s = s.replace(new RegExp('%%' + tp + '%%', 'g'), a[k]);
    }
    return s;
};
export function load(loaded) { return Object.assign(messages, loaded); }
export function get(name, replace) {
    let value;
    if (messages[name]) {
        value = messages[name];
        if (replace) {
            value = value.tr(replace);
        }
        return value;
    }
}

// MessageFormat system
let mfDefault = '', mfCurrent = '', mfQueryOrder = [], mfAvailable = [], mfCache = {}, mfCachedModules = {};
let initResolve;
const initDone = new Promise((resolve,reject) => {initResolve=resolve;});
initDone.then(() => mfLoadModuleAsyncInternal('common'));
export function init(defaultLanguage, currentLanguage, availableLanguages)
{
    mfDefault = defaultLanguage; mfCurrent = currentLanguage; mfAvailable = availableLanguages;
    if(!mfCurrent) {
        const browserLanguage = navigator.language.indexOf('-') > -1 ? navigator.language.slice(0,navigator.language.indexOf('-')) : navigator.language;
        if(mfAvailable.indexOf(browserLanguage) > -1) {
            for(let i = 0; i < mfAvailable.length; i++)
                if(browserLanguage == mfAvailable[i]) {
                    mfCurrent = mfAvailable[i]; break;
                }
        }
    }
    const mfCurrentRoot = mfCurrent.indexOf('-') !== -1 ? mfCurrent.slice(0,mfCurrent.indexOf('-')) : mfCurrent;
    const mfDefaultRoot = mfDefault.indexOf('-') !== -1 ? mfDefault.slice(0,mfDefault.indexOf('-')) : mfDefault;
    mfQueryOrder = [mfCurrent];
    if(mfCurrentRoot !== mfCurrent)
        mfQueryOrder.push(mfCurrentRoot);
    if(mfQueryOrder.indexOf(mfDefault) === -1)
        mfQueryOrder.push(mfDefault);
    if(mfQueryOrder.indexOf(mfDefaultRoot) === -1)
        mfQueryOrder.push(mfDefaultRoot);
    initResolve();
}
function mfLoadModuleInternal(lang,module,data)
{
    if(mfCachedModules[lang] === undefined) {
        mfCache[lang] = {}; mfCachedModules[lang] = [];
    }
    Object.assign(mfCache[lang],data['default']);
    mfCachedModules[lang].push(module);
    return true;
}
async function mfLoadModuleAsyncInternal(module)
{
    if(!mfQueryOrder.length)
        throw new Error('Failed loading language module "'+module+'". No language specified.');
    let data = null, i;
    for(i = 0; i < mfQueryOrder.length; i++) {
        try {
            data = await import('../../lang/'+mfQueryOrder[i]+'/'+module+'.json');
            break;
        } catch { }
    }
    if(data)
        return mfLoadModuleInternal(mfQueryOrder[i],module,data);
    else
        throw new Error('Failed loading language module "'+module+'" for "'+mfQueryOrder[i-1]+'" language.');
}
export async function mfLoadModule(module)
{
    if(/[^A-Za-z0-9\/_]/.test(module)) throw new Error('Incorrect module name');//return false;
    if(mfCachedModules[mfGetUILanguage()] && mfCachedModules[mfGetUILanguage()].indexOf(module) > -1) return true;
    return await initDone.then(() => mfLoadModuleAsyncInternal(module));
}
export function mfGetUILanguage()
{
    return mfCurrent || mfDefault;
}

export function __(message,data,language)
{
    if(!language && mfCurrent)
        language = mfCurrent;
    if(message.split('.').length < 2)
        return 'INVALID_LANGSTR_KEY';
    const module = message.slice(0,message.indexOf('.'));
    if(!language || mfAvailable.indexOf(language) === -1 || mfCache[language] === undefined || mfCachedModules[language] === undefined || mfCachedModules[language].indexOf(module) === -1) {
        language = null;
        for(let i = 0; i < mfQueryOrder.length; i++) {
            if(mfCache[mfQueryOrder[i]] !== undefined && mfCachedModules[mfQueryOrder[i]] !== undefined && mfCachedModules[mfQueryOrder[i]].indexOf(module) !== -1) {
                language = mfQueryOrder[i]; break;
            }
        }
    }
    if(!language)
        return 'LANG_NOT_LOADED';
    if(mfCache[language][message] === undefined)
        return 'LANGSTR_NOT_FOUND';
    return mfCache[language][message](data) || 'LANGSTR_ERROR';
}

export default {load,get,init,__};
