$ = require "jquery"
{scrollTo} = require "jquery"
{keys, map, filter, first, forEach} = require "lodash"
{__} = require "core/lang"
{ajax} = require "core/ajax.coffee"
{error, notice} = require "core/messages.coffee"
{textPreview, registry, prepareJSON, spoilerHandler, contentMediaParser, contentRemoveBadChars} = require "core/tools.coffee"
blocks = require "lib/blocks.coffee"
routes = require("lib/routes").default
{commentFor} = require "lib/markitup.coffee"
{watchForRevoting} = require 'app/vote.coffee'

types =
  topic:
    url_add: routes.topic.comment
    url_response: routes.topic.respond
  talk:
    url_add: routes.talk.comment
    url_response: routes.talk.respond

classes =
  #form_loader: 'loader'
  new: 'comment-new'
  current: 'comment-current'
  deleted: 'comment-deleted'
  wrapper: 'comment-wrapper'
  self: 'comment-self'
  #folded: 'folded'
  comment: 'comment'
  comment_goto_parent: 'goto-comment-parent'
  comment_go_back: 'comment-go-back'
  comment_hidden: 'comment-hidden'
  comment_folded: 'comment-folded'
  level: 'comment-level-'

hideClasses = [classes.self, classes.new, classes.deleted, classes.current]

iCurrentShowFormComment = 0
currentViewedComment = null

allComments = document.getElementsByClassName classes.comment
newComments = document.getElementsByClassName classes["new"]
foldedComments = document.getElementsByClassName classes.comment_folded
newCounter = document.getElementById "new_comments_counter"
allCounter = document.getElementById "count-comments"
commentForm = document.getElementById "form_comment_text"
updateButton = document.getElementById "update-comments"
message = document.getElementById "hidden-message"
originalTitle = document.title
topicAuthor = document.querySelector ".topic-info a[rel=author]"

toggleCommentFormState = (enable) ->
  submitButton = document.getElementById "comment-button-submit"

  if enable
    #commentForm.classList.remove classes.form_loader
    commentForm.readOnly = false
    submitButton.classList.remove 'loading'
    submitButton.disabled = false
  else
    #commentForm.classList.add classes.form_loader
    commentForm.readOnly = true
    submitButton.classList.add 'loading'
    submitButton.disabled = true
  null

add = (formId, targetId, targetType) ->
  toggleCommentFormState false

  _success = (result) ->
    unless result
      return error __("common.errors.server"), __("common.errors.try_later")
    if result.bStateError
      return error result.sMsgTitle || __("common.errors.common"), result.sMsg
    else
      commentForm.value = ""
      ls.comments.savedCommentForms[iCurrentShowFormComment] = "" # Если комментарий отправился успешно - нам больше не нужно бекапить его форму
      toggleCommentForm iCurrentShowFormComment, true
      load targetId, targetType, false

  _complete = (res) ->
    toggleCommentFormState true

  ajax types[targetType].url_add, prepareJSON(document.getElementById(formId)), _success, _complete

toggleCommentForm = (idComment, bNoFocus, scrollIntoView) ->
  reply = document.getElementById 'reply'
  unless reply then return

  preview = document.getElementById "comment_preview_#{iCurrentShowFormComment}"
  preview?.parentNode?.removeChild preview

  ls.comments.savedCommentForms[iCurrentShowFormComment] = commentForm.value

  if iCurrentShowFormComment == idComment and 'h-hidden' not in reply.classList and !scrollIntoView
    if idComment == 0
      document.querySelector('.reply-header>a').classList.add('active')
    reply.classList.add 'h-hidden'
    return

  commentNode = document.getElementById "comment_id_#{idComment}"
  commentNode.parentNode.insertBefore reply, commentNode.nextSibling
  if idComment == 0
    document.querySelector('.reply-header>a').classList.remove('active')
  reply.classList.remove 'h-hidden'

  commentForm.value = ls.comments.savedCommentForms[idComment] || ""
  document.getElementById("form_comment_reply").value = idComment
  iCurrentShowFormComment = idComment
  if scrollIntoView
    `setTimeout(()=>{ reply.scrollIntoView(/*{'behavior':'auto','block':'start'}*/); },0);`
  unless bNoFocus
    commentForm.focus()


toggleEditForm = (idComment, bOpen) ->
  contentWrapper = document.getElementById "comment_content_id_#{idComment}"
  if !document.querySelector "#comment_id_#{idComment} .buttons-ready"
    document.querySelector("#comment_id_#{idComment} .comment-edit-bw").outerHTML =
      '<a class="link-dotted comment-edit-bw buttons-ready">Изменить</a>
      <a class="link-dotted active comment-save-edit-bw">Сохранить</a>
      <a class="link-dotted comment-preview-edit-bw">Предпросмотр</a>
      <a class="link-dotted dangerous comment-cancel-edit-bw">Отмена</a>'
  if bOpen
    preview = document.createElement "div"
    preview.className = "text preview"
    preview.id = "comment_preview_edit_#{idComment}"
    currentText = contentWrapper.querySelector ".text.current"
    editForm = document.createElement "div"
    editForm.id = "comment_edit_#{idComment}"
    editForm.className = "edit-form"
    edit = document.createElement "textarea"
    edit.className = "markitup-editor"
    edit.id = "comment_edit_input_#{idComment}"
    edit.style.height = (currentText.getBoundingClientRect().height * 1.2 + 40) + "px"
    edit.value = currentText.innerHTML.replace(/<br[\s]*\/?>\r?\n/gmi, "\n").trim()
    editForm.appendChild preview
    editForm.appendChild edit
    bCommentSelf = contentWrapper.parentNode?.classList.contains "comment-self"
    if Capabilities.allowCommentsEditingLock and !bCommentSelf and document.querySelector("#comment_id_#{idComment} .modify-notice")?.dataset.locked != "1"
      lockCB = document.createElement "input"
      lockCB.type = "checkbox"
      lockLabel = document.createElement "label"
      lockLabel.classList.add "lock-edit"
      lockLabel.appendChild lockCB
      lockLabel.appendChild document.createTextNode " " + __("common.comments.actions.lock.title_in_editor")
      editForm.appendChild lockLabel
    contentWrapper.parentNode?.classList.add "editable"
    contentWrapper.appendChild editForm
    commentFor edit
    edit.focus()
    $(edit).on 'keydown', ({keyCode, which, ctrlKey}) ->
      key = keyCode or which
      if ctrlKey and key == 13 # Ctrl+Enter — сохранение коммента
        document.querySelector("#comment_id_#{idComment} > .comment-info > .comment-actions .comment-save-edit-bw").click()
      else if key == 27 and confirm('Отменить редактирование?') # Esc — скрытие/сворачивание формы редактирования комментария
        closeEditForm idComment, contentWrapper
  else
    closeEditForm idComment, contentWrapper
  false

closeEditForm = (idComment, contentWrapper) ->
  $("#comment_edit_#{idComment}").remove()
  contentWrapper.parentNode?.classList.remove "editable"

load = (idTarget, typeTarget, bFlushNew=true) ->
  idCommentLast = parseInt(newCounter.dataset.idCommentLast) || 0
  updateButton.classList.add 'active'
  params =
    idCommentLast: idCommentLast
    idTarget: idTarget
    typeTarget: typeTarget

  _success = (result) ->
    if result.bStateError
      return error __("common.errors.common"), result.sMsg

    comments_count = keys(result.comments).length;

    if bFlushNew
      while newComments.length
        newComments[0].classList.remove classes["new"]

    # раскрываем схлопнутые комменты перед показом новых, чтобы корректно сбросить те новые, которые в свёрнутых пользователем ветвях
    if(comments_count > 0)
      unfoldComments();

    forEach result.comments, (comment, id) ->
      unless document.getElementById "comment_id_"+id
        inject comment

    setCountNewComment()
    `allCounter.textContent = __(typeTarget == 'talk' ? 'common.messenger.messages.count' : 'common.comments.count',{'N':allComments.length});`

    if(comments_count > 0)
      curItemBlock = blocks.getCurrentItem 'stream'
      if curItemBlock?.dataset.type == 'comment'
        blocks.load curItemBlock, 'stream'
      newCounter.dataset.idCommentLast = result.iMaxIdComment

  _complete = -> updateButton.classList.remove 'active'

  ajax types[typeTarget].url_response, params, _success, _complete


inject = ({pid, id, html}) ->
  newComment = document.createElement 'div'
  newComment.classList.add classes.wrapper
  newComment.id = "comment_wrapper_id_#{id}"
  newComment.innerHTML = html
  level = 0

  if pid
    target = document.getElementById "comment_wrapper_id_#{pid}"
    forEach target.classList, (className) ->
      if className.match classes.level
        level = parseInt(className.replace(classes.level,"")) + 1
  else
    target = document.getElementById "comments"

  newComment.classList.add (classes.level + level)
  target.appendChild newComment
  commentAuthor = newComment.querySelector ".comment-author"
  if commentAuthor != null && commentAuthor.querySelector(':scope>span').textContent.trim() == topicAuthor.textContent.trim()
    commentAuthor.classList.add "comment-topic-author"
    commentAuthor.setAttribute "title", "Автор"
  if newComment.getElementsByClassName(classes.self).length
    if UI.autoFocusOnSend
      showComment id
  else
    if(!UI.expandHiveWithNewComments && newComment.querySelector(':scope>.comment').getClientRects().length == 0)
      newComment.querySelector(':scope>.comment').classList.remove(classes["new"])
    watchForRevoting(newComment)

toggle = (obj, commentId) ->
  url = routes.comment.delete
  params = idComment: commentId

  _success = (result) ->
    unless result
      return error __("common.errors.server"), __("common.errors.try_later")
    if result.bStateError
      return error __("common.errors.common"), result.sMsg

    notice null, result.sMsg
    comment = document.getElementById "comment_id_#{commentId}"
    forEach hideClasses, (className) -> comment.classList.remove className
    if result.iState
      comment.classList.add classes.deleted
    if result.iState == 2 && !result.allowRestore
      obj.closest('.comment-info').prev('.comment-content').children('.text').html(result.deletedNotice).removeClass('text current')
      obj.closest('.comment-info').remove()
    else
      obj.text result.sTextToggle
      if result.iState > 0
        notice_class = 'comment-deleted-notice'
        if(result.iState == 1) then notice_class += ' by-admin'
        obj.addClass('comment-repair').removeClass('comment-delete dangerous').parent().find('.link-dotted:last')
          .after('<span class="'+notice_class+'">'+result.deletedNotice+'</span>')
      else
        obj.addClass('comment-delete dangerous').removeClass('comment-repair').closest('.comment-info').find('.comment-deleted-notice').remove()

  ajax url, params, _success


saveEdit = (idComment) ->
  url = routes.comment.edit
  editForm = document.getElementById "comment_edit_#{idComment}"
  params =
    idComment: idComment
    newText: editForm?.querySelector("textarea")?.value
    setLock: if editForm?.querySelector('label>input[type="checkbox"]')?.checked then "1" else "0"

  _success = (result) ->
    unless result
      return error __("common.errors.server"), __("common.errors.try_later")
    if result.newText
      document.querySelector("#comment_content_id_#{idComment} .text.current").innerHTML = result.newText
    if result.notice
      if document.querySelector("#comment_id_#{idComment} .modify-notice")
        document.querySelector("#comment_id_#{idComment} .modify-notice").outerHTML = result.notice
      else
        document.querySelector("#comment_id_#{idComment} .comment-info .comment-date").insertAdjacentHTML('beforeend',result.notice)
    if result.bStateError
      return error result.sMsgTitle, result.sMsg
    else
      toggleEditForm idComment, false
      return notice result.sMsgTitle, result.sMsg

  ajax url, params, _success
  false

previewEdit = (idComment) ->
  preview_id = "comment_preview_edit_#{idComment}"
  document.getElementById(preview_id).innerHTML = ""
  textPreview "comment_edit_input_#{idComment}", false, preview_id, true
  return false

preview = ->
  unless commentForm.value
    return

  old_preview = document.getElementById "comment_preview_#{iCurrentShowFormComment}"
  old_preview?.parentNode?.removeChild old_preview

  new_preview = document.createElement "div"
  new_preview.className = "comment-preview text"
  new_preview.id = "comment_preview_#{iCurrentShowFormComment}"

  reply = document.getElementById("reply")
  reply.parentNode.insertBefore new_preview, reply

  textPreview 'form_comment_text', false, "comment_preview_#{iCurrentShowFormComment}"


setCountNewComment = () ->
  unless newCounter then return
  if newComments.length
    if UI.newCommentsInTitle
      document.title = newComments.length + ' | ' + originalTitle
    newCounter.textContent = newComments.length
    newCounter.classList.remove "h-hidden"
  else
    if UI.newCommentsInTitle
      document.title = originalTitle
    newCounter.classList.add "h-hidden"


`/** Раскрывает все схлопнутые ветки комментариев. */
const unfoldComments = function() {
    while(foldedComments.length)
        foldedComments[0].classList.remove(classes.comment_folded);
    message.classList.add('h-hidden');
}

/** Раскрывает все схлопнутые ветки комментариев.
  *  Если UI.expandHiveWithNewComments == true, то так же раскрывает и свёрнутые по кнопке "+" ветви, внутри которых находится заданный.
  * @param {Element} comment
  * @param {boolean} forceExpandHive true, чтобы раскрывать свёрнутые ветви независимо от UI.expandHiveWithNewComments.
  * @return {boolean} true если комментарий стал виден на странице после манипуляций, false если нет (т.е. один из его родительских контейнеров всё ещё скрыт).
 */
const revealComment = function(comment, forceExpandHive) {
    if(comment.getClientRects().length)
        return true;
    // раскрытие всех свёрнутых по принципу "скрыто N комментариев - показать"
    unfoldComments();
    // раскрытие всех свёрнутых по кнопке "+" ветвей, в которых находится коммент
    if(forceExpandHive === undefined) forceExpandHive = false;
    if(UI.expandHiveWithNewComments || forceExpandHive) {
        let p = comment.closest('.comment-wrapper.collapsed');
        if(p) {
            do {
                p.classList.remove('collapsed');
            } while(p = p.closest('.comment-wrapper.collapsed'));
        }
    }
    return comment.getClientRects().length !== 0;
};`

showComment = (commentId, highlightParent) ->
  "use strict"
  `let comment = null;
  if(commentId) {
      comment = document.getElementById('comment_id_'+commentId);
      if(!revealComment(comment, true)) return;
  } else {
      do {
          comment = newComments[0];
          if(comment) {
              comment.classList.remove(classes["new"]);
              // функция раскрытия коммента вызывается всегда, но ошибку покажем только если коммент не раскрылся и UI.expandHiveWithNewComments == true
              if(!revealComment(comment) && UI.expandHiveWithNewComments)
                  alert(__('common.comments.tree.actions.cycle_new_comments.errors.expanding_hive.main')+(newComments.length !== 0 ? __('common.comments.tree.actions.cycle_new_comments.errors.expanding_hive.next'):''));
          }
      } while(comment && comment.getClientRects().length === 0);
  }
  if(!comment) {
      setCountNewComment();
      return;
  }`
  commentWrapper = comment.parentNode
  parentWrapper = commentWrapper.parentNode
  parentComment = parentWrapper.children[0]
  if UI.autoDespoil && !highlightParent
    spoilerHandler($(comment).find(".spoiler"), 'despoil')
  if (UI.autoFold && !highlightParent) || (UI.autoFoldGoTo && highlightParent)
    toFold = null
    if parentWrapper.id != "comments"
      toFold = $(commentWrapper).prevAll(".comment-wrapper")
    hiddenCount = $(toFold).find(".comment").length
    if hiddenCount
      unfoldComments()
      forEach toFold, (wrapper) ->
        wrapper.classList.add(classes.comment_folded)
      message.children[0].innerHTML = __('common.comments.count_hidden',{'N':hiddenCount})
      parentWrapper.insertBefore(message, toFold[0])
      message.classList.remove('h-hidden')
  if currentViewedComment != null
    currentViewedComment.classList.remove classes.current
  focusComment = comment
  if highlightParent
    parentComment.classList.add classes.current
    currentViewedComment = parentComment
    focusComment = parentComment
    parentComment.querySelector(".go-back")?.remove()
    goBack = document.createElement 'a'
    goBack.classList.add "go-back"
    goBack.dataset.id = commentId
    goBack.title = "Вернуться"
    parentComment.querySelector(".comment-info").insertBefore(goBack, parentComment.querySelector(".comment-favourite"))
  else
    comment.classList.remove classes["new"]
    comment.classList.add classes.current
    currentViewedComment = comment
  shift = (window.innerHeight - focusComment.getClientRects()[0].height) / 2
  if shift < 0 then shift = 0
  #if UI.smothScroll
  #  $.scrollTo focusComment, 300, {offset: -shift}
  #else
  window.scrollBy(0, focusComment.getClientRects()[0].top - shift)
  setCountNewComment()

initEvent = ->
  if commentForm
    ls.comments.savedCommentForms = {}
    $(commentForm).on 'keydown', ({keyCode, which, ctrlKey}) ->
      key = keyCode or which
      if ctrlKey and key == 13 # Ctrl+Enter — отправка коммента
        $('#comment-button-submit').click()
      else if key == 27 # Esc — скрытие/сворачивание окна комментария
        ls.comments.toggleCommentForm(document.getElementById('form_comment_reply').value)
    $("#comment_subscribe").on 'change', () ->
      ls.subscribe.toggle this.dataset.target_type+'_new_comment', this.dataset.target_id, '', this.checked
    $(".reply-header>a").on 'click', () ->
      ls.comments.toggleCommentForm 0
    $('.comments-header .reply').on 'click', () ->
      ls.comments.toggleCommentForm 0, false, true
    $("#comment-button-submit").on 'click', () ->
      commentForm.value = contentRemoveBadChars contentMediaParser commentForm.value
      ls.comments.add 'form_comment', this.dataset.target_id, this.dataset.target_type
    $("#comment-button-preview").on 'click', () ->
      commentForm.value = contentRemoveBadChars contentMediaParser commentForm.value
      ls.comments.preview()
    $(document)
      .on('click', ".comments-allowed .reply-link", () ->
        ls.comments.toggleCommentForm this.parentNode.dataset.id)
      .on('click', ".is-admin .comment-delete, .comment-self .comment-delete, .is-admin .comment-repair", () ->
        confirm_text_key = 'common.comments.actions.delete.confirm.admin'
        if($(this).hasClass('comment-repair'))
          confirm_text_key = 'common.comments.actions.restore.confirm'
        else if($(this).closest('.comment').hasClass('comment-self'))
          confirm_text_key = 'common.comments.actions.delete.confirm.self'
        if(confirm(__(confirm_text_key)))
          ls.comments.toggle($(this), this.parentNode.dataset.id))
      .on('click', ".comment-self .comment-edit-bw, .is-admin .comment-edit-bw, .is-moder .comment-edit-bw", () ->
        ls.comments.toggleEditForm this.parentNode.dataset.id, true)
      .on('click', ".comment-save-edit-bw", () ->
        editForm = document.getElementById "comment_edit_input_" + this.parentNode.dataset.id
        editForm.value = contentRemoveBadChars contentMediaParser editForm.value
        ls.comments.saveEdit this.parentNode.dataset.id)
      .on('click', ".comment-preview-edit-bw", () ->
        editForm = document.getElementById "comment_edit_input_" + this.parentNode.dataset.id
        editForm.value = contentRemoveBadChars contentMediaParser editForm.value
        ls.comments.previewEdit this.parentNode.dataset.id)
      .on('click', ".comment-cancel-edit-bw", () ->
        ls.comments.toggleEditForm this.parentNode.dataset.id, false)

  $("#hidden-message>a").on 'click', () ->
    this.parentNode.classList.add 'h-hidden'
    $('.'+classes.comment_folded).removeClass classes.comment_folded
  $(document)
    .on('click', ".comment-level-1 .goto-comment-parent", (event) ->
      ls.comments.showComment this.closest('.comment').dataset.id, true
      event.preventDefault())
    .on('click', ".go-back", (event) ->
      ls.comments.showComment this.dataset.id, false
      this.remove()
      event.preventDefault())
    .on('click', ".folding", () ->
      this.parentNode.classList.toggle('collapsed');
      #$(this).toggleClass 'folded'
      #$(this).nextAll().toggleClass 'h-hidden'
    )

  if updateButton
    $(updateButton).on 'click', () ->
      ls.comments.load this.dataset.target_id, this.dataset.target_type
    if UI.autoUpdateComments
      autoUpdate = ->
        if document.visibilityState != 'hidden' then return
        if !$("#reply").hasClass("h-hidden") then return
        if $(updateButton).hasClass("active") then return
        load updateButton.dataset.target_id, updateButton.dataset.target_type, false
      setInterval autoUpdate, 60000
    if newCounter
      $(newCounter).on 'click', () ->
        ls.comments.showComment()
      if UI.hotkeys
        $(document).keydown (e) ->
          key = e.keyCode or e.which
          if [32,45].indexOf(key) != -1
            if ["TEXTAREA","INPUT"].indexOf(document.activeElement.nodeName) == -1
              e.preventDefault()
              $(newCounter).click()
          else if [13,46].indexOf(key) != -1
            if ["TEXTAREA","INPUT"].indexOf(document.activeElement.nodeName) == -1
              e.preventDefault()
              $("#update-comments").click()

init = ->
  initEvent()

  # подсветка комментария при переходе по ссылке с "якорем" на этот комментарий
  if(location.hash && location.hash.startsWith('#comment'))
    comment = document.getElementById('comment_id_'+location.hash.slice(8));
    if(comment && revealComment(comment, true))
      if(UI.autoDespoil)
        spoilerHandler($(comment).find(".spoiler"), 'despoil')
      comment.classList.remove classes["new"]
      comment.classList.add classes.current
      currentViewedComment = comment

  setCountNewComment()
  if commentForm && UI.smartQuote
    $(document)
      .on('mouseup', (e) ->
        #проверяем нажата ли левая кнопка
        if e.which != 1 then return
        selection = window.getSelection()
        text = selection
          .toString()
          .replace /&/g, "&amp;"
          .replace /</g, "&lt;"
          .replace />/g, "&gt;"
          .replace /"/g, "&quot;"
          .replace /'/g, "&#039;"
        if !text then return
        #получаем всех родителей
        parents = $(selection.anchorNode.parentElement)
        parents = $(parents).add($(parents).parents())
        #проверка на допустимость цитирования
        if $(parents).filter("textarea").length then return
        if !$(parents).filter(".comment-content,.topic-content").length then return
        if($(parents).filter(".comment:first").hasClass('comment-deleted') || $(parents).filter(".comment:first").hasClass('comment-hidden')) then return
        #ищем родительский комментарий
        parentID = $(parents).filter(".comment")[0]?.dataset.id || 0;
        contentRect = document.getElementById("content").getBoundingClientRect()
        x = e.clientX - contentRect.left + 10;
        y = e.clientY - contentRect.top - 9;
        quote = document.getElementById "quote"
        if text != quote.dataset.quote
          quote.dataset.quote = text
          quote.dataset.parent_id = parentID
          $(quote).css 'left', x+'px'
          $(quote).css 'top', y+'px'
          $(quote).show()
      )
      .on('mousedown', ->
        $("#quote").hide()
      )
      .on('mouseup', '#quote', (e) ->
        e.stopPropagation()
        #выбираем форму для вставки
        targetForm = $("textarea[id^='comment_edit']")[0]
        if !targetForm
          targetForm = commentForm
          if $("#reply").hasClass "h-hidden"
            iCurrentShowFormComment = this.dataset.parent_id
            toggleCommentForm iCurrentShowFormComment, !UI.autoFocusSmartQuote
          else
            iCurrentShowFormComment = $("#reply").siblings(".comment")[0]?.dataset.id || 0
        else
          iCurrentShowFormComment = targetForm.id.replace "comment_edit_input_", ""
        #ищем каретку в форме редактирования
        caret = targetForm.selectionStart
        if isNaN caret
          targetForm.value += '<blockquote>'+this.dataset.quote+'</blockquote>'
        else
          targetForm.value = 
            targetForm.value.substring(0,caret) +
            '<blockquote>'+this.dataset.quote+'</blockquote>' +
            targetForm.value.substring(caret)
        $(this).hide()
        #если форма редактирования не видна, мотаем
        targetFormPosition = $(targetForm).offset().top
        windowPosition = $(window).scrollTop()
        if UI.autoFocusSmartQuote && ((targetFormPosition + targetForm.getClientRects()[0].height < windowPosition) || (targetFormPosition > (windowPosition + $(window).height())))
          comment = document.querySelector("#form_comment_text")
          #if UI.smothScroll
          #  $.scrollTo(comment, 300, {offset: -250})
          #else
          comment.focus()
      )
      .on('mousedown','#quote', (e) ->
        if e.which != 1
          $(this).hide()
        e.stopPropagation()
      )


module.exports = {
  init
  toggleCommentForm
  toggle
  toggleEditForm
  previewEdit
  saveEdit
  add
  preview
  load
  showComment
}
