###*
# Компонент входа и регистрации
###

React = require 'react'
Login = require("lib/react-login-component/login.jsx").default

{ajax} = require "core/ajax.coffee"
routes = require("lib/routes").default
{__,mfLoadModule} = require "core/lang"

lang_loaded = mfLoadModule('components/login');

# Helpers
`const prepareLoginLocale = (props) => {
    const locale = {'lang': document.documentElement.lang};
    props.map((prop) => locale[prop] = __((prop.indexOf('.') == -1 ? 'components/login.' : '')+prop));
    return locale;
}`

loginAjax = (login, password, remember, callback) ->
  params =
    'login': login
    'password': password
    'return-path': window.location.href
  if remember then params.remember = 'on'
  ajax routes.profile.login, params, (result) ->
    if result.bStateError
      callback 'err', result.sMsg
    else
      window.location = result.sUrlRedirect

reminderAjax = (email, callback) ->
  ajax routes.profile.reminder, {'mail': email}, (result) ->
    if result.bStateError
      callback 'err', result.sMsg
    else
      callback 'ok', result.sMsg

registrationAjax = (username, email, password, recaptcha, callback) ->
  params =
    'login': username
    'mail': email
    'password': password
    'password_confirm': password
    'g-recaptcha-response': recaptcha
    'return-path': window.location.href
  ajax routes.profile.registration, params, (result) ->
    if result.sUrlRedirect
      callback 'ok', "<strong>#{__('components/login.activation_title')}</strong><br />#{__('components/login.activation_description')}"
    else
      callback 'err', result.sMsg

validateAjax = (field, value, okMsg, callback) ->
  ajax routes.profile.validateFields, {'fields[0][field]': field, 'fields[0][value]': value}, (result) ->
    if result.aErrors
      callback 'err', result.aErrors[field][0]
    else
      callback 'ok', __('components/login.entered_correctly')

validateUsername = (value, callback) ->
  if value.length == 0
    return callback 'err', __('components/login.empty_username')
  else if value.length < 3
    return callback 'err', __('components/login.username_too_short')
  else if value.lenght > 30
    return callback 'err', __('components/login.username_too_long')
  else if not /^[a-zA-Z0-9-_]+$/.test value
    return callback 'err', __('components/login.invalid_username')
  else
    return validateAjax 'login', value, __('components/login.username_available'), callback

validateEmail = (value, callback) ->
  re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  if value.length == 0
    return callback 'err', __('components/login.empty_email')
  else if not re.test value
    return callback 'err', __('components/login.invalid_email')
  else
    return validateAjax 'mail', value, __('components/login.valid_email'), callback

onLogin = ({login, password, remember}, callback) =>
  loginAjax login, password, remember, callback

onRegister = ({username, email, password, recaptcha}, callback) =>
  registrationAjax username, email, password, recaptcha, callback

onRemind = ({email}, callback) =>
  reminderAjax email, callback

validateUsernameTimeout = null
validateEmailTimeout = null
validateTimeoutMs = 500

onValidate = (type, value, callback) =>
  switch type
    when 'username'
      clearTimeout(validateUsernameTimeout)
      run = () ->
        validateUsername value, callback
      validateUsernameTimeout = setTimeout(run, validateTimeoutMs)
    when 'email'
      clearTimeout(validateUsernameTimeout)
      run = () ->
        validateEmail value, callback
      validateEmailTimeout = setTimeout(run, validateTimeoutMs)

      
# Initialization
`export default (props = {}) => {
    return React.createElement(Login,Object.assign({
        isModal: true,
        initiallyHidden: true,
        hasNavigation: true,
        isLabeled: false,
        recaptcha: { key: RECAPTCHA_KEY },
        tabs: [
          { 'id': 'enter', 'url': '/login' },
          { 'id': 'registration', 'url': '/registration' },
          { 'id': 'reminder', 'url': '/login/reminder' }
        ],
        locale: prepareLoginLocale,
        langModulePromise: lang_loaded,
        onLogin: onLogin,
        onRegister: onRegister,
        onRemind: onRemind,
        onValidate: onValidate
    }, props));
}
`
